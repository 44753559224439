import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import useStyles from './styles';
import organizer from 'assets/partners/organização/logo_L&L.png';
import radio from 'assets/partners/radio/antena3.png';

// Fetch partners data with axios
const fetchPartners = async () => {
  const response = await axios.get('https://oyster-app-jiunm.ondigitalocean.app/airtable-data', {
    params: {
      table_name: 'Partners',
    },
  });
  return response.data;
};

const Partners = ({ lang }) => {
  const classes = useStyles();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['partners'],
    queryFn: fetchPartners,
  });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error: {error.message}</p>;

  // Organize data by title and images
  const partnersByTitle = data.reduce((acc, record) => {
    const title = record.fields.Name;
    const titlePT = record.fields["Title PT"] || title; // Use Title PT if available, fallback to Name
    const images = record.fields.Images || [];

    if (!acc[title]) {
      acc[title] = {
        title: title,
        titlePT: titlePT,
        images: [],
      };
    }

    acc[title].images.push(
      ...images.map((img) => ({
        url: img.thumbnails?.large?.url || '',
        alt: img.filename || '',
      }))
    );

    return acc;
  }, {});


  return (
    <div className={classes.partnerSection}>
      <p className={classes.partnersTitle}>
        {lang === 'en' ? 'Partners' : 'Parceiros'}
      </p>

      <div className={classes.partnersMaxWidth}>
        {/* Dynamic partner sections based on titles and images */}
        {Object.entries(partnersByTitle).map(([_, { title, titlePT, images }], index) => (
          <React.Fragment key={index}>
            <p className={classes.partnerTitle}>
              {title !== 'Main' && (lang === 'en' ? title : titlePT)}
            </p>
            <div className={classes.partnerList}>
              {images.map((image, idx) => (
                <div className={classes.partner} key={idx}>
                  <img
                    src={image.url}
                    alt={image.alt}
                    loading="lazy"
                    className={title === 'Main' ? `big` : ''}
                  />
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}


      </div>
    </div>
  );
};

Partners.propTypes = {
  lang: PropTypes.string,
};

export default Partners;
