import React from 'react';
import { Link } from 'react-router-dom';

import useStyles from './styles.js';

import Button from 'components/button/index.js';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';

const fetchSpeakers = async () => {
  const response = await axios.get('https://oyster-app-jiunm.ondigitalocean.app/airtable-data', {
    params: {
      table_name: 'Speakers',
    },
  });
  return response.data;
};


const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const SpeakersList = ({ lang }) => {
  const classes = useStyles();

  const { data, error, isLoading } = useQuery({
    queryKey: ['speakers'],
    queryFn: fetchSpeakers,
  });

  function capitalizeEveryWord(str) {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  function formatNameForUrl(name) {
    return name.toLowerCase().replace(/\s+/g, '-');
  }

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;


  const shuffledSpeakers = shuffleArray(data);

  return (
    <div className={classes.speakersSection}>
      {lang === 'en' && <p className={classes.speakersTitleXL}>Confirmed Speakers</p>}
      {lang === 'pt' && <p className={classes.speakersTitleXL}>Oradores confirmados</p>}

      <div className={classes.maxWidth}>
        <div className={classes.speakersList}>
          {shuffledSpeakers
            .slice(0, 3) // Select the first 3 speakers after shuffling
            .map((speaker) => (
              <Link
                to={`/speaker/${formatNameForUrl(speaker.fields.Name)}`} // Using speaker name in the URL
                className={classes.speakers}
                key={speaker.id}
              >
                <div className="col justify-center align-center" style={{ width: '100%' }}>
                  <div className={classes.imageContainer}>
                    {speaker.fields.Images && speaker.fields.Images.length > 0 && (
                      <img
                        src={speaker.fields.Images[0].thumbnails.large.url}
                        className={classes.image}
                        alt={speaker.fields.Name}
                        loading="lazy"
                      />
                    )}
                  </div>

                  <p className={classes.speakersTitle}>
                    {capitalizeEveryWord(speaker.fields.Name)} <span>{speaker.fields.Country}</span>
                  </p>
                  <p className={classes.speakersAbout}>{speaker.fields['Role-PT']}</p>
                </div>
              </Link>
            ))}
        </div>

        <Link to="/conference" className="no-underline">
          <Button background="green" color="brown" fontSize="28px">
            {lang === 'en' ? 'View All' : 'Ver Todos'}
          </Button>
        </Link>
      </div>
    </div>
  );
};

SpeakersList.propTypes = {
  lang: PropTypes.string,
};

export default SpeakersList;
